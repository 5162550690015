<template>
  <div class="w100 bgw" style="min-height:100vh;">
    <div class="w100 fs15" style="min-height: 100vh;" :style="{ 'padding-bottom': $route.path.includes('thankyou') ? '170px' : '' }">
      <router-view></router-view>
    </div>

  </div>
</template>
<script>
export default {
  name: "MainLayout",
  data() {
    return {
      lang:false,
    }
  },
  created() {
      this.$i18n.locale="en";
      this.lang=true
      this.changeLocale('en')
    // if($("#bea_css").length){}
    // else{
    //   $("head").append(
    //   '<link rel="stylesheet" type="text/css" href="../bea.css" id="bea_css">'
    // );
    // }

  },
  watch: {
    "lang":function(new_lang){
      if(new_lang){
        this.changeLocale('en')
      }
      else{
        this.changeLocale('ar')

      }
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      $("html").attr("lang", "ar");
      if (locale == "ar") {
        $('body').addClass("rtl");
      } else {
        $("html").attr("lang", "en");
        $('body').removeClass("rtl");
      }
    },
  }
};
</script>
