import { createStore } from "vuex";

export default createStore({
  state: {
    // BaseApiURL: "https://cpdsymposium.manialab.sa/api/",
    BaseApiURL: "https://cpdsymposium.com/api/",

    loader: 0,
    tokennuser: "",
    speakers:[
      {
        name:"Prof. Aws Alshamsan",
        img:"n_s_1.png",
        desc:"Secretary General of the Saudi Commission for Health Specialties",
        bio:"Before his appointment as Secretary General of the Saudi Commission for Health Specialties, Aws Alshamsan was a professor of pharmacy and pharmaceutical sciences at King Saud University College of Pharmacy and dean of the college since 2017. Previously, he worked as a consultant for biological products at the Saudi Food and Drug Authority for five years. His extensive administrative and managerial experience includes directorship of the Research Center at the College of Pharmacy at KSU between 2012 and 2013. ",
      },
      {
        name:"Ms. Manal Alnasser",
        img:"n_s_2.png",
        desc:"Executive Director of Professional Development, Saudi Commission for Healthcare Specialities",
        bio:"She occupied a number of leading positions in several health organizations and leading universities, including the director of medical follow-up at King Fahd Medical City and the director of Higher Studies Training Center at King Saud Bin Abdulaziz University under the Ministry of National Guard. \nShe also worked as the director of Higher Studies and Scholarships Department at King Abdullah Bin Abdulaziz University Hospital and was the executive director of Skills Development Center at Princess Nourah University. She attended and participated in numerous training programs.",
      },

      {
        name:"Dr. Reem Albunyan",
        img:"s_14.png",
        desc:"The Center for Value in Health (Saudi Arabia)",
        bio:"Dr. Reem Bunyan is the CEO of the Center for Improving Value in Health which is part of the evolving transformed Health System in the Kingdom of Saudi Arabia; a national independent center that fosters the enhancement of the national health system by improving value in health and contributing to building a true learning health system. \nShe co-chaired the G20 Health Working Group in the year 2020 where she led discussions on the topic Improving Value in Health Systems among other important topics in Global Health. G20 Members supported and welcomed the establishment of a Global Innovation Hub for improving Value in Health with voluntary membership of G20 members and other nations. The Hub’s secretariat is hosted in the Center and Reem serves as its Chief Executive. In May of 2021, she was awarded the honorable King Abdulaziz Order of Merit (Second Class) for this work.",
      },
      {
        name:"Dr. Graham McMahon",
        img:"n_s_3.png",
        desc:"President and Chief Executive Officer",
        bio:"Graham McMahon, MD, MMSc, is the President and Chief Executive Officer of the Accreditation Council for Continuing Medical Education (ACCME®), which sets standards for high-quality continuing medical education (CME) that improves physician competence and performance and contributes to healthcare improvement for patients and their communities. Dr. McMahon leads efforts to strengthen the role of accredited CME as a strategic resource to public health initiatives on the national and international level. He is responsible for positioning accredited CME to effectively meet the needs of emerging generations of healthcare professionals.",
      },
      {
        name:"Prof. Abdulaziz Boker",
        img:"s_1.png",
        desc:"MBBS(Hon), CHEdT, FRCPC, Med",
        bio:'Prof. Abdulaziz Boker  Professor, Consultant & Chairman, Department of Anesthesia and Critical Care  College of Medicine General Supervisor, Clinical Skills and Simulation Center King Abdulaziz University, Jeddah, Saudi Arabia Prof. Boker is the current Chairman of the Exam committee of the Saudi Scientific Boards of Anesthesia Care at Saudi Commission of Health Specialties (SCHS) . Boker led a major curriculum reform for the national training program in anesthesia and critical care medicine in Saudi Arabia and many other Arab countries. He obtained his medical college degree from King Abdulaziz University, Jeddah with honors. He then obtained Royal College of Canada certification in anesthesia and masters of medical education, Prof. Boker has very active involvement in medical education and Health Simulation at local, regional, national, and international levels.',
      },
      {
        name:"Dr.Yousra Alatiq",
        img:"n_s_4.png",
        desc:"DPhil Clinical Psychology",
        bio:"	Dr.Yousra was Head of Clinical Psychology Section, Dep of Adult Mental Health at King Abdulaziz Medical City. She was also the Chairman of Professional Council for Clinical Specialties. She is currently the CEO of Professional Practice at SCFHS and also a  Consultant  in Clinical Psychologist at PsychCare",
      },
      {
        name:"Prof. Ahmad Aboshaiqah",
        img:"s_4.png",
        desc:"",
        bio:"is an executive  and academic leader with a globally twenty plus years of experienced in healthcare and nursing leadership and management. Prof. Aboshaiqah is the first professor of nursing in Saudi Arabia and first Fellowship of American Academy of Nursing (FAAN). Currently, he is a consultant and a full professor in the college of nursing, King Saud University. \n Professor Aboshaiqah is the founder of Saudi Nurses Association. He served as a dean in the college of nursing, KSU from 2014 – 2019. Worked as a chairman of the nursing board at the Saudi Commission for Health Specialties (SCFHS) . \n He is a certified KSU quality board assessor.",
      },
      {
        name:"Dr. Mohammed Alhamid",
        img:"s_11.png",
        desc:"",
        bio:"Is the Director of the Centre of Healthcare Intelligence (CHI) at King Faisal Specialist Hospital & Research Centre (KFSH&RC). At CHI, Dr. Alhamid works with clinical and operational leaders to build and deploy high-quality applied Healthcare Artificial Intelligence (AI) solutions for enhancing quality of care, streamlining workflow operations, and improving patient experience using best practice operational and responsible AI pipelines. Prior to his current role, Mohammad was an Advisory and AI Technical Lead at IBM US/Canada as well as a faculty and Chairman of the Software Engineering Department at King Saud University. Dr. Alhamid has more than 60 publications and submitted 8 patent applications. Dr. Alhamid holds a Ph.D. and master degree in Computer Sciences from the University of Ottawa, Canada",
      },
      {
        name:"Dr. Faisal Alotaibi",
        img:"s_9.png",
        desc:"",
        bio:"Is a professor of neurological surgery at King Faisal Specialist & Research Centre, Alfaisal University in Riyadh, Saudi Arabia. He obtained his training at the Western University in Canada and the London Health Sciences Centre in Canada and was a Fellow of the American College of Surgeons. Afterward, he joined King Faisal Specialist & Research Centre in Riyadh and Alfaisal University. Dr. Alotaibi is a founding board member of the International Epilepsy Surgery Society IESS and the president of the Saudi Epilepsy Society. He joined the board director of the World Society of Stereotactic and Functional Neurosurgery. Recently became a board member of the Education Committee at the International League Against Epilepsy. He is the director of neurorestoration and chairperson of the research committee-Academic and Training Affairs at King Faisal Specialist Hospital and Research Centre and ",
      },
      {
        name:"Dr. Alaa Nazzal",
        img:"s_5.png",
        desc:"",
        bio:"is a board-certified Internist, practicing as a hospitalist and primary care physician at the International Medical Center (IMC) in Jeddah, Saudi Arabia. \n His passion for medical education is evident through his various roles in the medical education continuum, Graduate Medical Education (GME), and Continuous Professional Development (CPD). Dr. Alaa currently serves as the Executive Director of the Learner Experience and Academic Affairs Division at IMC. Additionally, he holds the position of Program Director for Internal Medicine Residency Training, where he ensures learner-centricity by designing and facilitating transformative learning experiences. Dr. Alaa places significant emphasis on professional identity formation as a crucial competency in shaping the future of physicians. ",
      },
      {
        name:"Ms. Dina Alismail",
        img:"s_8.png",
        desc:"",
        bio:"She worked as the Executive Director of Academic and Training Affairs at King Abdullah bin Abdulaziz University Hospital of Princess Nourah University. Before that she was the Director of Training and Scholarship at King Khaled Eye Specialist Hospital. She’s an ICF certified executive coach, and a master trainer by the American Association of Talent Development. With more than 23 years of experience in training and development, she joined the Saudi Commission for Health Specialties as the Executive Director of the Healthcare Leadership Academy.",
      },
      {
        name:"Dr. Wejdan Almunive",
        img:"s_female.png",
        desc:"E-learning professional and E-learning Section Head at the SCFHS.",
        bio:"She is specialize in designing and developing highly interactive and engaging e-learning experiences tailored specifically for healthcare practitioners. I create effective learning modules that enhance knowledge and skills in the healthcare field, ultimately contributing to the professional development of the healthcare sector in Saudi Arabia",
      },
      {
        name:"Ms. Reema Althunayyan",
        img:"s_female.png",
        desc:"Learning Experience Designer, Digital Learning Department, Saudi Commission for Health Specialties.",
        bio:"A dedicated professional in the field of Public Health with a focus on Health Promotion and Education, Reema is driven by a strong desire to make meaningful contributions to the advancement of healthcare professionals' knowledge and skills; by specializing in the design and development of highly interactive e-learning experiences, She aim to contribute significantly to the professional development of the healthcare sector in Saudi Arabia.",
      },
      {
        name:"Ms. Maha Abuzenada",
        img:"s_female.png",
        desc:"Business Analyst- E-Learning Content Development Section - Saudi Commission for Health Specialties",
        bio:"A dedicated, professional and experienced business analyst working within the eLearning department with an extensive background in the research and development of healthcare systems. Specialized in analysing the learning needs of the healthcare sector through identifying key areas where eLearning solutions can be optimized to enhance the knowledge and performance of healthcare professionals to effectively translate complex healthcare concepts into engaging and impactful eLearning experiences",
      },
      {
        name:"Dr. Lulu Alwazan",
        img:"s_female.png",
        desc:"",
        bio:"Dr Lulu Alwazzan is the Chairperson of the Department of Medical Education (DME) at Imam Mohammad Ibn Saud Islamic University’s College of Medicine in Riyadh, Saudi Arabia. Through continuous development programs, she fosters the development of health practitioners in their leadership, educational, and research roles.",
      },
      {
        name:"Dr. Reem Alserour",
        img:"s_female.png",
        desc:"",
        bio:"Participated in various clinical research at KADC. Planned, organized, and conducted international educational dental events, including The New Dental Era international Conferences (2005-2008). Dr. Reem also led the faculty developmental program for King Saud bin Abdul-Aziz University for Health Specialties (KSAU-HS) and the Ministry of Health (2010-2013). \n In 2019 became a CPD consultant at SCFHC-CPD executive department where we Initiated a national program to create a new CPD-accreditation system in Saudi Arabia, aligned with the 2030 vision. Developed and implemented training programs for CPD-providers, CPD-scientific content reviewers, and SCFHS-CPD specialists- 2022 CPD champion certificate.",
      },
      {
        name:"Dr. Saud Alturki",
        desc:"MD, FRCS, ODTS-FACA ",
        img:"s_15.png",
        bio:"He is the Chief Executive Officer of Academy of Sciences in Riyadh & Jeddah, KSA. He is the current Advanced Trauma Life Support (ATLS) Chairman of Saudi Arabian Chapter. He did the proliferation of ATLS Program for doctors all over the Kingdom, and in the Gulf States (Qatar, Bahrain, Kuwait). The ATLS Program is now established in more than 50 sites within the Kingdom and the Gulf States. ATLS program is now a mandatory course for all surgeons, emergency physicians, residents, and interns. \n He is an active Medical Director for Pre-Hospital Trauma Life Support (PHTLS) Program, and Advanced Trauma Care for Nurses (ATCN) Program. The course was initiated in Riyadh and has been spread out in at least 6 cities in the Kingdom. PHTLS program is now a mandatory training program for all paramedics, EMTs, and technicians. While, ATCN Program is a trauma course designed for Nurses. The course was initiated in Riyadh and had introduced to different cities in Saudi Arabia, Qatar, Bahrain, and Kuwait. These programs are actively running in the Middle East",
      },
      {
        name:"Dr. Mohammed Alnimnim",
        img:"s_12.png",
        desc:"",
        bio:"pediatric Emergency Specialists Leader at King Abdullah Specialist Children’s Hospital-Riyadh.  He Participated Speaker and Trainer in: All Level Trauma Care Conference (ALTC) speaker and Basic Life Support (AHA) Instructor/ TCF (BLS)  and Advanced Cardiac Life Support (AHA) Instructor/ TCF (ACLS) and Pediatrics Advanced Life Support (AHA) Instructor / TCF (PALS)",
      },
      {
        name:"Dr. Abdulaziz Aljohani",
        img:"s_2.png",
        desc:"",
        bio:'He is a board certified in palliative care, Acute Pain Management, Family Medicine and Medical Education. He earned his Saudi board and Arab board of Family Medicine in 2007. In 2012, He completed a residency in palliative care and pain management at university of British Columbia, Vancouver, Canada. In 2013, He completed "Leadership Training in Simulation for Health Education" Fellowship at center of Excellence for Simulation Education and Innovation (CESEI), University of British Columbia, Vancouver, BC, Canada.',
      },
      {
        name:"Dr. Abeer Alahmadi",
        img:"s_female.png",
        desc:"MD, MBBS, SBFM, ABFM, MMD",
        bio:"Deputy director postgraduate family medicine joint program in Jeddah Family medicine consultant and women health specialis Founding Member of the Saudi Society for Women's Health Member of the Health Empowerment and Health Rights Unit at King Abdelaziz University .Member of Domestic Violence and Neglect Protection Committee, Ministry of Health,Jeddah. Member of the Support Association for the Care of Divorced Women, Widows, and Their Children ,Makkah region",
      },
       {
        name:"Dr. Asim Alsuwaiyan",
        img:"s_7.png",
        desc:"BDS, MS",
        bio:"Consultant in Periodontics and Implant Dentistry at King Fahd Military Medical Complex. He is Team Member, Dental Ensiab Committee, MOD Health Services. Member of the the  American Academy of periodontology (AAP) and  International Team of Implantology (ITI) .",
      },
      {
        name:"Dr. Abdullah Alamri",
        img:"s_3.png",
        desc:"",
        bio:"Consultant in Periodontics and dental implant, King Saud University Medical city. He was also irector of dental administrative department, General directorate of health affair in Riyadh region. Dr.alamri ia currently the Chairman ,Periodontics committee , Saudi Dental Licensure Examination Council , Saudi Commission for Health Specialties.",
      },
      {
        name:"Dr. Noof Albaz",
        img:"n_s_5.png",
        desc:"Ph.D. Medical Education",
        bio:"Director of Master of Medical Education Program (MME) COLLEGE OF MEDICINE At KING SAUD BIN ABDULAZIZ UNIVERSITY FOR HEALTH SCIENCES. Dr.Noof is also the director, of Continuing Professional Development Cpd Deanship Of Postgraduate Education In King Saud Bin Abdulaziz University For Health Science. She is also an Assistant Professor Department Of Medical Education, College Of Medicine King Saud Bin Abdulaziz University For Health Sciences. Member of the Saudi Society for Medical Education (SSME)., also a Member of the Saudi Association of Health Informatics (SAHI)",
      },
      {
        name:" Dr. Alia Alzawawi",
        img:"n_s_6.png",
        desc:"",
        bio:"Associated Dean in Postgraduate education at King Saud bin Abdulaziz University for Health Sciences. She is also a trainer of Family Medicine Residency Training Program at king Abdullaziz Medical City. \n Dr.Alia was Associate Dean Continuing Professional Development at Deanship of Postgraduate Education. She is a family medicine consultant at Ministry of National Guard Health Affairs. She is also a member of the board of directors of Saudi society family and community medicine. ",
      },
      {
        name:"Dr. Usamah Alzoraigi",
        img:"n_s_8.png",
        desc:"",
        bio:" is a highly accomplished medical professional with extensive expertise in the field of Anesthesia and Simulation Medicine. With a strong educational background, Dr. Alzoraigi obtained his MBBS degree from King Saud University (KSU) in Riyadh, Saudi Arabia in 2002. He further pursued specialized training, including a Residency in Anesthesia & ICU in Saudi Arabia. Dr. Alzoraigi also completed a Regional Anesthesia Fellowship at the Medical University of Vienna in Austria and a Simulation Medicine Fellowship at the STARTUS Simulation Center, affiliated with Brigham and Women's Hospital and the Center of Medical Simulation at Harvard University in Boston, USA.",
      },
      {
        name:"Dr. Abdullah Almarshed",
        img:"n_s_7.png",
        desc:"",
        bio:"Is a highly accomplished medical professional with extensive experience in medical simulation and education. He completed a medical simulation fellowship at Neil and Elise STRATUS Center for Medical Simulation at Brigham and Women’s Hospital/Harvard Medical School and a master’s in medical education at King Saud bin Abdulaziz University for Health Sciences in Riyadh. Currently, he serves as the Director of CRESENT, the simulation center in King Fahad Medical City, and also works as an emergency medicine consultant at the same hospital.",
      },
      {
        name:"Mr. Mohammed Abdali",
        img:"s_10.png",
        desc:"",
        bio:"Team Leader Entrepreneurship Culture  at Monshaat. Mr.Mohammed has Masters in Biotechnology from The University of Queensland.",
      },
    ],

      stories :[
        {
          name:"Dr.Fawziah Al Mutairi  ",
          img:"s_female.png",
          desc:"Q.I. Pharmacist",
          bio:"Pharmaceutical Care Services, king Abdulaziz medical city 23 years of experience providing excellent pharmacy services.(out/ in-patient and quality improvement section) MSc Pharmacology, 2 yrs experience as pharmacogenetics research scholar at Tufts university-Boston Certified : Medication safety, TOT, sterile compounding and aseptic technique",
        },
        {
          name:"Dr. Naif Marzook",
          img:"ss_naif_marzook.png",
          desc:"Consultant Emergency Medicine",
          bio:"Consultant Emergency Medicine, Chairman of the Emergency Department at King Fahad Armed Forces Hospital, Fellowship in Disaster Medicine & Emergency Medical Services from Brown University, USA , Executive Master of Business Administration from Prince Mohammed Bin Salman College.",
        },

        {
          name:"Mr. Ahmed Aldemerdash",
          img:"ss_ahmed_aldermerdash.jpg",
          desc:"BScPhm, PharmD, BCPSAssistant",
          bio:"Professor of Clinical Pharmacy Consultant, Postgraduate Pharmacy Education Center Medical City at King Saud University",
        },
        {
          name:"Dr. Reem Alserour",
          img:"s_female.png",
          desc:"",
          bio:"- Bachelor's Degree in Dental Sciences, King Saud University (2000) - Advanced Education in General Dentistry, University of South California - Developed and implemented training programs for CPD-providers, CPD-scientific content reviewers, and SCFHS-CPD specialists- 2022 CPD champion certificate",
        },
        {
          name:"Dr. Nora Alkhudair",
          img:"ss_nora_alkhudair.png",
          desc:"BSc Pharm, PharmD",
          bio:'Assistant Professor at the Clinical Pharmacy Department, College of Pharmacy, King Saud University, and a consultant Clinical Pharmacist in Hematology/Oncology and stem cell transplant at King Saud University Medical City. She is currently the Hematology/Oncology residency program director at King Saud University. Dr. Alkhudair is the Chairwoman of the Saudi Oncology Pharmacy Assembly.',
        }
    ],
    onlineMaterial:[
      {
        title:"Needs assessment for CPD providers",
        name:"Dr Bander Albagawi",
        bio:"Dr Bander Albagawi, RN, BSN, MSN, PhD, is an Associate Professor and former Dean in the College of Nursing at the University of Hail, Saudi Arabia. He specializes in Nursing Simulation, Leadership and Management in Nursing, and Quality Improvement. Dr. Albagawi is actively involved in cooperative projects with the Saudi Health Academy and holds memberships in prestigious international nursing journals.",
        objectives:`
          <ul>
            <li>Summarize main concepts of the Training Needs of Assessment (TNA).</li>
            <li>Recognized TNA approaches in order to inform healthcare professional CPD.</li>
            <li>Highlight the recommended strategies for TNA application.</li>
          </ul>
        `,
      },
      {
        title:"Team-Based Learning",
        name:"Dr Maisoon AlUkaily",
        bio:"Dr. Maisoon AlUkaily is the Chief of Professional Development in the Eastern Health Cluster. She is a primary care physician with a master's degree in medical education from the University of Sydney. With over 15 years of experience, Dr. AlUkaily is dedicated to creating a learning culture and has led impactful projects in medica education.",
        objectives:`
          <ul>
            <li>Define the concept of team-base learning.</li>
            <li>Describe the principles of team-based learning.</li>
            <li>Illustrate the different steps involved in delivering team-based learning.</li>
            <li>Explain the benefits of applying the TBL concept.</li>
          </ul>
        `,
      },
      {
        title:"Tools to translate professional development into practice for improved patient care.",
        name:"Dr Alia Zawawi",
        bio:"Associate dean postgraduate education at KSAUHS, consultant family medicine at MNGHA, senior trainer at family medicine residency training program. Assistant professor collage of medicine KSAUHS.",
        objectives:`
          <ol>
            <li>Interpret the relation between CPD and patient outcome.</li>
            <li>Explain the outcome evaluation in different levels.</li>
            <li>Setup an evaluation plan for continues professional development.</li>
            <li>Explore the tools that can be used to measure the CPD outcome in relation to patient outcome!</li>
          </ol>
        `,
      },
      {
        title:"Establishing an effective CPD online platform",
        name:"Dr Kieran Walsh",
        bio:"Dr. Kieran Walsh is the Clinical Director at BMJ, overseeing medical education and clinical decision support resources. With extensive experience in online medical education, face-to-face teaching, and assessment, he has contributed to strengthening health systems. Additionally, Dr. Walsh holds the position of Adjunct Associate Professor at Monash University and has made significant contributions to the field through over 200 published papers and four books on medical education.",
        objectives:`
          <p class="b">How to establish and maintain an e-
          learning platform
          Curricular development/instructional
          design: How to choose topics and design
          courses for an online platform and how to
          conduct assessments.
          The uses of BMJ online by the individual
          practitioner</p>
        `,
      },
      {
        title:"Scientific research skills",
        name:"Dr Ximena Alvira",
        bio:"Ximena Alvira is a medical doctor, neuroscientist, and experienced medical writer. She currently serves as the Clinical and Research Manager at Elsevier, providing strategic counsel to organizations on research objectives, solution utilization, and scientific writing skills. Ximena has conducted over 230 health research publishing classes and workshops worldwide, reaching 23,000 attendees.",
        objectives:`
          <ul>
            <li>Develop an understanding of the pivotal
            role of research and an evidence-based
            culture in Continuous Professional
            Development.</li>
            <li>Provide a basic understanding of the
            research process.</li>
            <li>Inspire participants to pursue further
            training in research in Continuous
            Professional Development and become
            active contributors to the research
            community.</li>
            <li>Foster the development of research that is
            ethical, transparent, and of high quality.</li>
          </ul>
        `,
      },
    ]
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
