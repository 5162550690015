import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Website/home.vue"),
  },
  {
    path: "/online-material",
    name: "onlineMaterial",
    component: () => import("../views/Website/onlineMaterial.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    meta: {
      layout: "MainLayout",
    },
    component: () => import("../views/Registration/step1.vue"),
  },
  {
    path: "/registration-2024",
    name: "Registration2024",
    meta: {
      layout: "MainLayout",
    },
    component: () => import("../views/Registration/step1_host.vue"),
  },
  
  {
    path: "/registration/step2",
    name: "RegStep2",
    meta: {
      layout: "MainLayout",
    },
    component: () => import("../views/Registration/step2.vue"),
  },
  {
    path: "/survey",
    name: "Survey",
    meta: {
      layout: "MainLayout",
    },
    component: () => import("../views/Registration/survey.vue"),
  },
  {
    path: "/g-certifcate",
    name: "GCertifcate",
    component: () => import("../views/Registration/g_certificate.vue"),
  },
  {
    path: "/certificate",
    name: "Certificate",
    component: () => import("../views/Registration/w_certificate.vue"),
  },
  {
    path: "/thankyou",
    name: "ThankYou",
    meta: {
      layout: "MainLayout",
    },
    component: () => import("../views/Registration/thankyou.vue"),
  },
  {
    path: "/badge",
    name: "badge",
    // meta: {
    //   layout: "MainBadgeLayout",
    // },
    component: () => import("../views/Registration/badge.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userAuthenticated = localStorage.getItem("agent_token") !== null;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresNoAuth = to.matched.some(
    (record) => record.meta.requiresNoAuth
  );

  if (requiresAuth && !userAuthenticated) {
    next("/login");
  } else if (requiresNoAuth && userAuthenticated) {
    next("/check");
  } else {
    next();
  }
});

export default router;
