<template>
  <div class="w100 " id="navbar" style="min-height:100vh">
    <div class="pf ov z9000 bgb fs0 ba">
      <!-- <img src="@/assets/imgs/web/navI.png"  class="w100" alt="" style="max-height: 100px; min-height: 40px;"> -->
      <!-- <img src="@/assets/imgs/web/top_pattern.png"  class="wa" alt="" style="max-width: 100%; min-heigdht: 8px;"> -->
      <div class="pv10 m_pv5" style="background-color:#EAEAEA">
        <div class="flexi">
          <tbl_nm>
            <cl class="vm fs10 w10 m_w50 l">
              <!-- <img src="@/assets/imgs/logo_sas_f.png"  @click="gotoinnerlink('navbar')" class="w100 pointer" alt="" style="max-width: 80px; "> -->
            </cl>
            <cl class="colSep"></cl>
            <cl class="vm fs14  m_h w80">
              <tbl_nm>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4" @click="gotoinnerlink('about')">{{ $t('about_conference') }}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4" @click="gotoinnerlink('program')">{{$t('program')}}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('Sessions_Section')">{{ $t('sessions_details') }}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('Speakers_Section')">{{$t('speakers')}}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                 <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('FAQ_Section')">{{$t('faqs')}}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('Contact_Section')">{{ $t('contact_us') }}</div>
                  </div>
                </cl>

              </tbl_nm>
            </cl>
            <cl class="w0 vm r m_w50 ">
              <div class="h g_nm m_sib" style="z-index: 999999999;">
                <span class="mdi mdi-menu menu-btn fs20" @click="showSideBar()"> </span>
              </div>
              <div class="g_nm" style="z-index: 999999999;">
                <span class="mdi mdi-earth fs30 cBlue pointer" @click="changeLocale()"></span>
              </div>
            </cl>
            <!-- <cl class="colSep "></cl>
            <cl class="vm w10 m_w50 r" style="min-width: 150px;">
              <router-link :to="{ name: 'RegStep1' }">

              <button class="wRegBtn ph10 wCLGray pointer pv8 m_pv2 m_ph4 fs14 m_fs12">
                <span class="vm mdi fs18 mdi-account-plus-outline"></span>
                <span class="vm b ml4">REGISTER NOW</span>
              </button>
              </router-link>

            </cl> -->
          </tbl_nm>
        </div>
      </div>
    </div>
    <div class="vh bgb fs0 ba">
      <!-- <img src="@/assets/imgs/web/navI.png"  class="w100" alt="" style="max-height: 100px; min-height: 40px;"> -->
      <!-- <img src="@/assets/imgs/web/top_pattern.png"  class="wa" alt="" style="max-width: 100%; min-heigdht: 8px;"> -->
      <div class="pv10 m_pv5" style="background-color:#EAEAEA">
        <div class="flexi">
          <tbl_nm>
            <cl class="vm fs10 w10 m_w50 l">
              <!-- <img src="@/assets/imgs/logo_sas_f.png"  @click="gotoinnerlink('navbar')" class="w100 pointer" alt="" style="max-width: 80px; "> -->
            </cl>
            <cl class="colSep"></cl>
            <cl class="vm fs14  m_h w80">
              <tbl_nm>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4" @click="gotoinnerlink('about')">{{ $t('about_conference') }}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4" @click="gotoinnerlink('program')">{{$t('program')}}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('Sessions_Section')">{{ $t('sessions_details') }}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('Speakers_Section')">{{$t('speakers')}}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                 <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('FAQ_Section')">{{$t('faqs')}}</div>
                  </div>
                </cl>
                <cl class="vm colSep"></cl>
                
                <cl class="vm">
                  <div class="g_nm pv2 bbGreen" >
                    <div class="navItem  g_nm ph10 pointer pv4"  @click="gotoinnerlink('Contact_Section')">{{ $t('contact_us') }}</div>
                  </div>
                </cl>
                

                

              </tbl_nm>
            </cl>
            <cl class="w0 vm r m_w50 ">
              <div class="h g_nm m_sib" style="z-index: 999999999;">
                <span class="mdi mdi-menu menu-btn fs20" @click="showSideBar()"> </span>
              </div>
              <div class="g_nm" style="z-index: 999999999;">
                <span class="mdi mdi-earth fs30 cBlue pointer" @click="changeLocale()"></span>
              </div>
            </cl>
            <!-- <cl class="colSep "></cl>
            <cl class="vm w10 m_w50 r" style="min-width: 150px;">
              <router-link :to="{ name: 'RegStep1' }">

              <button class="wRegBtn ph10 wCLGray pointer pv8 m_pv2 m_ph4 fs14 m_fs12">
                <span class="vm mdi fs18 mdi-account-plus-outline"></span>
                <span class="vm b ml4">REGISTER NOW</span>
              </button>
              </router-link>

            </cl> -->
          </tbl_nm>
        </div>
      </div>
    </div>
    
    <div class="pf ov  bgb pb20 pt130  fs16 cw l sideBar z100">
      <div class="navSideItem ph10 pointer pv4" @click="gotoinnerlink('about')"> {{ $t('about_conference') }}</div>
      <div class="navSideItem ph10 pointer pv4" @click="gotoinnerlink('program')"> {{ $t('program') }}</div>
      <div class="navSideItem ph10 pointer pv4"  @click="gotoinnerlink('Sessions_Section')"> {{ $t('sessions_details') }} </div>
      <div class="navSideItem ph10 pointer pv4"  @click="gotoinnerlink('Speakers_Section')"> {{ $t('speakers') }}</div>
      <div class="navSideItem ph10 pointer pv4"  @click="gotoinnerlink('FAQ_Section')"> {{ $t('faqs') }}</div>
      <div class="navSideItem ph10 pointer pv4"  @click="gotoinnerlink('Contact_Section')"> {{ $t('contact_us') }}</div>

    </div>
    <!-- <div class="pv10 r z100" style="background: linear-gradient(180deg, rgba(5,7,6,0.5) 0%,  rgba(39,136,123,1) 50%);">
      <div class="switch nFont mt10" style="z-index:9999999">
        <input id="language-toggle" class="check-toggle check-toggle-round-flat" v-model="lang" type="checkbox">
        <label for="language-toggle"></label>
        <span class="lh20 on">AR</span>
        <span class="lh20 off">EN</span>
      </div>
    </div> -->

    <div class="w100  fs15 ">
      <router-view></router-view>
    </div>
    <footer class=" cw pv30" id="Contact_Section" style="background-color: #4D4D4F;">
      <div class="flexi">
      <tbl>
        <cl class="vt w33 l">
          <!-- <img src="@/assets/imgs/logo_sas.png" class="w100 g_nm" alt="" style="max-width: 250px;"> -->
        </cl>
        <cl class="colSep40 pv10"></cl>
        <cl class="vt w33 l">
          <div class=" fs20 m_fs16 cw ">
            <span class="b fs18 m_fs16 cw vm">{{ $t('contact_us') }}:</span>
            <div><span class="mdi mdi-email"></span><a class="vm fs16 " href="mailto: saaec@mof.gov.sa"> SAAEC@mof.gov.sa</a></div>
            <div class="mt10"><p class="b fs18 m_fs16 cw">{{ $t('con_loc') }}:</p><a  class="pointer" target="_blank" href="https://maps.app.goo.gl/BAsY5zTJeei4ZcAs9"><span class="mdi mdi-map-marker"></span><span class="vm fs16">{{ $t('location') }} {{ $t('gar') }}</span></a></div>

            <!-- <tbl_nm>
              <cl class="vm w5 nw">{{ $t('contact_us') }}</cl>
              <cl class="colSep"></cl>
              <cl class="vm">
                <div class="mt4 pt2 bgw" style="max-width: 100px;" ></div>
              </cl>
            </tbl_nm> -->
          </div>
          <!-- <div class="mt20 l cw "> -->
             <!-- <tbl_nm class="mt15">
                <cl class=" vm b" style="width: 20px;"><span class="mdi mdi-map-marker fs20"></span></cl>
                <cl class="colSep"></cl>
                <cl class="w b vm">
                  <p>{{ $t('location') }}</p>
                </cl>
              </tbl_nm> -->
            <!--    <tbl_nm class="mt15">
                <cl class="w20 vt b">
                  Phone
                </cl>
                <cl class="colSep"></cl>
                <cl class="w80 vt">
                  <p>+966 055 555 555</p>
                  <p>+966 055 555 555</p>
                </cl>
              </tbl_nm> -->
                <!-- <tbl_nm class="cw mt15">
                  <cl class=" vm b" style="width: 20px;"><span class="mdi mdi-email fs20"></span></cl>
                <cl class="colSep"></cl>
                <cl class="wa b vm">
                  <p>SAAEC@mof.gov.sa</p>
                </cl>
            </tbl_nm> -->
          <!-- </div> -->
        </cl>
        <!-- <cl class="colSep40 pv10"></cl>
        <cl class="vt w33 l">
          <div class="b fs30 m_fs20 cw ">
            <p class="pt10 vt g_nm">Social Media </p><p class="vt g_nm">   ____________</p>
          </div>
          <div class=" mt20 fs15 m_fs14 l">
            <p class="mt15">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
          </p>
          </div>
          <div class="mt20 fs0">
            <div class="g_nm fs28 ph5"><span class="mdi mdi-youtube"></span></div>
            <div class="g_nm fs28 ph5"><span class="mdi mdi-google-plus"></span></div>
            <div class="g_nm fs28 ph5"><span class="mdi mdi-facebook"></span></div>
            <div class="g_nm fs28 ph5"><span class="mdi mdi-twitter"></span></div>
            <div class="g_nm fs28 ph5"><span class="mdi mdi-instagram"></span></div>
          </div>
        </cl> -->
      </tbl></div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "MainLayout",
  data() {
    return {
      lang:false,
    }
  },
  created() {
    // var lang = this.$route.query.lang;
    // if(lang == "en"){
    //   this.$i18n.locale="en";
    //   this.changeLocale()
    // }
    // else{
    //   this.$i18n.locale="ar";
    //   this.changeLocale()
    // }
    if($("#bea_css").length){}
    else{
        $("head").append(
        '<link rel="stylesheet" type="text/css" href="../bea.css" id="bea_css">'
      );
    }
    $(document).ready(function () {
            $(document).on("click", function (event) {
              if (!$(event.target).closest(".sideBar").length && !$(event.target).hasClass("menu-btn") && $(".sideBar").hasClass("active")) {
                  $('.sideBar').removeClass('active');
                }
            });
        });

  },
  watch: {
    // "lang":function(new_lang){
    //   if(new_lang){
    //     this.changeLocale('en')
    //   }
    //   else{
    //     this.changeLocale('ar')

    //   }
    // }
  },
  methods: {
    gotoinnerlink(section_id){
      // goto(id) {
      if (this.$route.name == "home") {
        var x = $("#" + section_id).offset().top - 70;
        $("html, body").animate(
          {
            scrollTop: x,
          },
          500
        );
      } else {
        var xname = "/";
        var idid = section_id.replace(/\s/g, "");
        this.$router.push({ path: xname, query: { sec: idid } });
      }
      $('.sideBar').removeClass('active');
    // },
      // if ($(section_id).length > 0) {
      //   $('html, body').animate({
      //     scrollTop: $(section_id).offset().top
      //  }, 500);
      //  $('.sideBar').removeClass('active');
      // }
    },
    showSideBar(){
      if($('.sideBar').hasClass('active')){
        $('.sideBar').removeClass('active');
      }
      else{
        $('.sideBar').addClass('active');
      }
    },
    changeLocale() {
      if(this.$i18n.locale=='en'){
        this.$i18n.locale='ar'
        $("html").attr("lang", "ar");
        $('body').addClass("rtl");
      }
      else {
        this.$i18n.locale='en'
        $("html").attr("lang", "en");
        $('body').removeClass("rtl");
      }
    },
  }
};
</script>
