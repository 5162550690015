<template>
  <div class="bgLightGreen w100 " style="min-height:100vh">
    
    <div v-if="$store.state.loader==1" class="pf ov bgw" style="z-index:999999999999999999;">
      <div class="cc">
        <div class="loader cLightGreen" style="color: #000 !important;"></div></div>
    </div>
    <div class="w100 fs15" style="min-height: 100vh;" :style="{ 'padding-bottom': $route.path.includes('thankyou') ? '170px' : '' }">
      <router-view></router-view>

    </div>

  </div>
</template>
<script>
export default {
  name: "MainBadgeLayout",
  data() {
    return {
      lang:false,
    }
  },
  created() {
    if($("#bea_css").length){}
    else{
      $("head").append(
      '<link rel="stylesheet" type="text/css" href="../bea.css" id="bea_css">'
    );
    }

  },
  watch: {
  },
  methods: {
  }
};
</script>
