<template>
  <div v-if="$store.state.loader==1" class="pf ov bgw10" style="z-index:999999999999999999;">
      <div class="cc">
        <div class="loader cLightGreen" style="color: #000 !important;"></div></div>
    </div>
  <router-view></router-view>
</template>
  
<script>
export default {
  name: "EmptyLayout",
}
</script>