import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import Select2 from "vue3-select2-component";
import "./assets/mdi.css";
import "./assets/bea.css";
import "./assets/myStylee.css";
import "maz-ui/css/main.css";
import VueQRCodeComponent from "vue-qrcode-component";
import VueBarcode from "@chenfengyuan/vue-barcode";
import Carousel3d from 'vue-carousel-3d';
import vue3StarRatings from "vue3-star-ratings";

const app = createApp(App).use(store).use(router);
app.use(i18n);
app.component("Select2", Select2);
app.use(VueAxios, axios);
app.component("qr-code", VueQRCodeComponent);
app.component(VueBarcode.name, VueBarcode);
app.component("vue3-star-ratings", vue3StarRatings);
app.use(Carousel3d);

app.mount("#app");
