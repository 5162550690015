import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  locale: 'en',
  messages: {
    'en': {
      submit:"Submit",
      about_you:"About You",
      your_name:"Your Name",
      first:"First",
      last:"Last",
      required:"required",
      position:"Position",
      company:"Organization",
      back:"Back",
      next:"Next",
      PhoneNumber:"PhoneNumber",
      your_email_address:"Your Email Address",
      email_address:"Email Address",
      confirm_email_address:'Confirm Email Address',
      name_is_required:"Please complete the following fields: ",
      email_is_required:"Oops. Please enter a valid email address",
      fileds_error:"There was a problem with your submission. Please review the fields below.",
      mobile:"Mobile",
      filed_is_required:"The field is required",
      email_does_not_match:"Your emails do not match.",
      phone_already_taken:"The phone number has already been used",
      email_already_taken:"The email has already been used",
      registration_successfully:"Thank you for registration. Please Check Your Email for E-Badge",
      registration_failed:"Registration was not completed. Please check required fields",
      ok:"OK",
      download_badge:"Download Badge",
      something_went_wrong:"Something went wrong please try again",
      wrong_link:"Sorry the link is incorrect",
      operationFailed:"Operation failed please try again",
      thankyou:"Thank You",
      email_is_invalid:"Oops. Please enter a valid email address",
      work_shop_title:"Which workshop you are intersted in?",
      choose_one:"Please choose one of each",
      morning_workshop:'Workshops ( 1:00 PM - 2:30 PM )',
      q1_ans1:"Designing Specialized Professional Program (SPP)",
      select_one:"Please select one",
      comments_title:"Inquiries",
      comments_text:"Please let us know if you have any inquiry.",
      your_comments:"Your Comments/Questions",
      copy_right_1:"All rights reserved 2023, Copyright ",
      the:"The ",
      fourth:"4th ",
      header_title:"CPD \n Symposium in Healthcare",
      // header_sub:"Never-ending Learning Journey",
      header_sub:"An Endless Learning Journey",
      date:"Date",
      date_1:"06, December 2023",
      place:"Venue",
      place_1:"J.W Marriott, Riyadh",
      days:"Days",
      hours:"Hours",
      minutes:"Minutes",
      seconds:"Seconds",
      reg_now:"REGISTER NOW",
      mission:"Mission",
      mission_text:"Our mission is to elevate continuous professional development and support capacity development programs for healthcare practitioners in partnership with practitioners, professional development organizations, and relevant sectors, in order to achieve quality healthcare and Vision 2030 programs.",
      objectives:"Objectives",
      objectives_text_1:"Share the latest CPD trends locally and internationally.",
      objectives_text_2:"Building Capacity in CPD and reinforce the essential values and knowledge.",
      objectives_text_3:"Discuss and create innovative opportunities for the CPD future.",
      themes:"Themes",
      themes_text_1:"Teaching & Learning",
      themes_text_2:"Leadership & Management",
      themes_text_3:"Innovation & Technology",
      speakers:"Speakers",
      events:"events",
      MORNING_SESSION:"MORNING SESSION",
      workshop_2:"WORKSHOPS ( 13:00 - 14:30 )",
      workshop_3:"WORKSHOPS ( 15:00 - 16:30 )",
      success_partner:"Success Partner",
      reg_now_m_s:"REGISTER NOW FOR MORNING SESSION",
      program:"Program",
      name:"Name",
      msg:"Message",
      email:"Email",
      contact_info:"Contact Inforamtion",
      Send:"Send",



    },
    'ar': {


    },
  },
})
export default i18n
